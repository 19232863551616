import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { KEY_RE_CAPTCHA, REDIRECT_APP_URL } from "../../config";
import { useRegDoc, useRegFlusso } from "../../hooks/useAnalytics";
import { disableBeforeUnloadPopup } from "../../hooks/useBeforeUnloadPopup";
import { autheLogin } from "../../services/login";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { utagLink, utagView } from "../../store/utagStore/utagStore.action";
import { wizardSelectorsFlusso } from "../../store/wizard/wizard.selectors";
import { loginSso } from "../../services/loginSso";

export function useAuthAction() {
  const dispatch = useDispatch();

  const formData = useSelector(formDataSelectors.getFormData);

  const authenticate = async () => {
    disableBeforeUnloadPopup();

    await authenticateLogin();
  };

  const authenticateLogin = async () => {
    const token = await window.grecaptcha.enterprise.execute(KEY_RE_CAPTCHA, {
      action: "LOGIN",
    });
    window.grecaptcha.token = token;

    try {
      const response = await autheLogin(formData, formData.channel);

      const dataObject = {
        billCodeId: response.billCodeId,
      };
      if (response.tokenJwt) dataObject["tokenJwt"] = response.tokenJwt;
      if (response.token) dataObject["token"] = response.token;
      if (response.tokenTouch) {
        dataObject["tokenTouch"] = response.tokenTouch;
      }
      if (response.flagAdvPlus) {
        dataObject["flagAdvPlus"] = response.flagAdvPlus;
      }

      dispatch(addFormData(dataObject));

      dispatch(
        utagLink({
          reg_event: "autologin_ok",
          reg_id_conto: formData.contoId,
        }),
      );
    } catch (err) {
      console.error("ERRORE LOGIN", err);

      dispatch(
        utagLink({
          reg_event: "autologin_ko",
          reg_err_code: JSON.stringify(err),
        }),
      );
    }
  };

  return { authenticate };
}

export function useAuthActionSso() {
  const { channel, sessionId, userAgent, username, password } = useSelector(
    formDataSelectors.getFormData,
  );

  const authenticateSso = async () => {
    disableBeforeUnloadPopup();

    try {
      const recaptchaToken = await window.grecaptcha.enterprise.execute(
        KEY_RE_CAPTCHA,
        { action: "LOGIN" },
      );

      const responseData = await loginSso({
        recaptchaToken,
        channel,
        sessionId,
        userAgent,
        username,
        password,
      });

      return responseData;
    } catch (err) {
      alert("autologin error");
    }
  };

  return { authenticateSso };
}

export function useCustomRedirect() {
  const dispatch = useDispatch();

  const history = useHistory();

  const formData = useSelector(formDataSelectors.getFormData);

  const customRedirect = (redirectUrl) => {
    const { channel, flagAdvPlus, token, tokenTouch, flagConto } = formData;

    switch (channel) {
      case "62":
        const urlDesktop = `${REDIRECT_APP_URL}/?auth-token=${token}&endcallbackurl=${redirectUrl}`;

        if (flagAdvPlus) {
          dispatch(addFormData({ typUrl: urlDesktop }));
          history.push("/questionario");
        } else {
          window.location = urlDesktop;
        }

        return;
      default:
        const urlMobile = `http://appmobile.sisal.it/?auth-token=${token}&jwtToken=${tokenTouch}&flagConto=${flagConto}`;

        if (flagAdvPlus) {
          dispatch(addFormData({ typUrl: urlMobile }));
          history.push("/questionario");
        } else {
          window.location = urlMobile;
        }
    }
  };

  return { customRedirect };
}

export function useDocValidationStatusCheck() {
  const formData = useSelector(formDataSelectors.getFormData);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);

  switch (flusso) {
    case "OCR":
      return "IN_PROGRESS";
    case "SPID":
      return formData.spidProcess ? "VALIDATED" : "TO_BE_UPLOADED";
    default:
      return "TO_BE_UPLOADED";
  }
}

export function useAnalyticsUtagView(completionPercentage) {
  const dispatch = useDispatch();

  const reg_flusso = useRegFlusso();

  const reg_doc = useRegDoc();

  const formData = useSelector(formDataSelectors.getFormData);

  const reg_bonus = !formData.promoCode
    ? formData.bonusCode
    : formData.promoCode;

  const reg_id_conto = formData.contoId;

  const loyaltyGameCodeExists = formData.loyalty.gameCodeExist;

  useEffect(() => {
    dispatch(
      utagView({
        conversion_pagename: `reg-typ:${completionPercentage}`,
        reg_flusso,
        country: "italia",
        reg_doc,
        reg_bonus,
        reg_id_conto,
        reg_pagename: "registrazione:reg_typ",
        ...(loyaltyGameCodeExists ? { reg_plus: "loyalty" } : {}),
      }),
    );
  }, [
    completionPercentage,
    dispatch,
    loyaltyGameCodeExists,
    reg_bonus,
    reg_doc,
    reg_flusso,
    reg_id_conto,
  ]);
}
