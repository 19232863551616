import { v4 as uuidv4 } from "uuid";
import { instancePost } from "../axios.config";
import { LOGIN_APP_URL, PORT } from "../config";

export async function autheLogin(formData, channel) {
  const res = await instancePost.post(
    `${LOGIN_APP_URL}${PORT}/authentication-ms/v1/login`,
    {
      username: formData.username,
      password: formData.password,
      recaptchaToken: window.grecaptcha.token,
    },
    {
      headers: {
        "X-Call-Id": uuidv4(),
        "X-Session-Id": formData.sessionId,
        "X-Channel-Id": channel.toString(),
        "X-Channel-Info": formData.userAgent,
      },
    },
  );

  return res.data;
}
