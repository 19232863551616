import { v4 as uuidv4 } from "uuid";
import { instancePost } from "../axios.config";
import { LOGIN_APP_URL, PORT } from "../config";

export async function loginSso({
  recaptchaToken,
  channel,
  sessionId,
  userAgent,
  username,
  password,
}) {
  const url = `${LOGIN_APP_URL}${PORT}/authentication-ms/v1/sso/login`;

  const res = await instancePost.post(
    url,
    {
      username,
      password,
      recaptchaToken,
    },
    {
      headers: {
        "X-Call-Id": uuidv4(),
        "X-Session-Id": sessionId,
        "X-Channel-Id": channel.toString(),
        "X-Channel-Info": userAgent,
      },
    },
  );

  return res.data;
}
