import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { KEY_RE_CAPTCHA } from "../../config";
import { useRegFlusso } from "../../hooks/useAnalytics";
import { disableBeforeUnloadPopup } from "../../hooks/useBeforeUnloadPopup";
import { useTranslate } from "../../hooks/useTranslate";
import { postGeneratePrize } from "../../services/loyaltyLottery";
import { postSignUp, postSignUpPdv } from "../../services/signUp";
import { addFormData } from "../../store/formData/formData.action";
import { formDataSelectors } from "../../store/formData/formData.selectors";
import { selectJumioAccountId } from "../../store/ocr/ocr.selectors";
import { spidStatusSelectors } from "../../store/spidStatus/spidStatus.selectors";
import { utagLink } from "../../store/utagStore/utagStore.action";
import {
  markSignUpCompleted,
  setIsLoading,
} from "../../store/wizard/wizard.action";
import { wizardSelectorsFlusso } from "../../store/wizard/wizard.selectors";
import { genericError } from "../errorCardsPage/errorCardsPage.helpers";

export function useSignUp() {
  const dispatch = useDispatch();

  const history = useHistory();

  const formData = useSelector(formDataSelectors.getFormData);
  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  const emailCertified = useSelector(spidStatusSelectors.isEmailCertified);
  const jumioAccountId = useSelector(selectJumioAccountId);

  const [isLoading, setLoading] = useState(false);

  const generateLoyaltyPrizeIfEnabled = useLoyaltyPrizeGeneration();
  const { catchErrorSignUp } = useSignUpErrorHandler();

  const signUp = async () => {
    disableBeforeUnloadPopup();

    dispatch(setIsLoading(true));
    setLoading(true);

    try {
      const recaptchaToken = await getAndSetOnWindowSignUpRecaptchaToken();

      try {
        const data = await postSignUp(
          formData,
          flusso === "SPID",
          emailCertified,
          recaptchaToken,
          jumioAccountId,
        );

        dispatch(markSignUpCompleted());

        window["ic_portal"] = data.contoId ? data.contoId.toString() : null;

        dispatch(
          addFormData({
            billCode: data.contoCode,
            contoId: data.contoId,
          }),
        );

        trackSignupWithPixie(data.contoId);

        history.push("/ThankYouPage");

        setLoading(false);

        generateLoyaltyPrizeIfEnabled(data.contoCode);
      } catch (signUpErr) {
        dispatch(setIsLoading(false));
        setLoading(false);
        catchErrorSignUp(signUpErr);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return { signUp, isLoading };
}

// registration for resellers only, not for end users
export function useSignUpPdv() {
  const dispatch = useDispatch();

  const history = useHistory();

  const flusso = useSelector(wizardSelectorsFlusso.getFlusso);
  const formData = useSelector(formDataSelectors.getFormData);
  const jumioAccountId = useSelector(selectJumioAccountId);

  const [isLoading, setLoading] = useState(false);

  const { catchErrorSignUp } = useSignUpErrorHandler();

  const signUpPdv = async () => {
    disableBeforeUnloadPopup();

    try {
      const recaptchaToken = await getAndSetOnWindowSignUpRecaptchaToken();
      try {
        setLoading(true);

        const data = await postSignUpPdv(
          formData,
          recaptchaToken,
          jumioAccountId,
        );

        dispatch(markSignUpCompleted());

        trackSignupWithPixie(data.contoId);

        window["ic_portal"] = data.contoId?.toString() ?? null;

        dispatch(
          addFormData({
            billCode: data.contoCode,
            billCodeId: data.contoId,
            contoId: data.contoId,
          }),
        );

        history.push(
          `/registrazione-effettuata-pdv/${flusso === "OCRPDV" ? "ocr" : "inserimento-manuale"}`,
        );
      } catch (signUpErr) {
        catchErrorSignUp(signUpErr);
      } finally {
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return { signUpPdv, isLoading };
}

function useSignUpErrorHandler() {
  const dispatch = useDispatch();
  const history = useHistory();

  const reg_flusso = useRegFlusso();

  const { getTranslatedString } = useTranslate();

  const catchErrorSignUp = (err) => {
    if (err.response?.status === 422) {
      const errorType = err.response.data.error.type;
      const errorInfos = err.response.data.error.errorInfos;

      if (!errorInfos) {
        history.push("/errore-registrazione");
        return;
      }

      if (history.location.pathname === "/errore-dati-non-corrispondenti") {
        alert(
          "Non è possibile proseguire con la registrazione, rivedi i tuoi dati",
        );
        return;
      }

      if (isPromoError(err.response?.data)) {
        dispatch(
          utagLink({
            reg_step: "riepilogo",
            conversion_pagename: "riepilogo",
            error_message: getTranslatedString(
              "err.bonusUnavailable.card.body",
            ),
            reg_flusso,
            reg_event: "registrazione|error-message|bonus-non-disponibili",
          }),
        );

        history.push("/errore-bonus");
        return;
      }

      const nameError = Object.keys(errorInfos)[0];
      const errorCode = errorInfos[nameError]?.[0];
      const summaryErrorCode = errorInfos.summary?.[0];

      if (errorType === "SGAD_VALIDATION" && errorCode === "err.e8014") {
        dispatch(addFormData({ nameError: "err.e8014" }));
      } else if (
        summaryErrorCode === "err.e8006" ||
        summaryErrorCode === "err.e8009" ||
        summaryErrorCode === "err.e8010"
      ) {
        history.push("/errore-dati-non-corrispondenti");
        return;
      } else {
        dispatch(addFormData({ nameError: errorCode }));
      }

      history.push("/errore");
    } else if (err.response?.status === 500) {
      dispatch(addFormData({ nameError: genericError }));
    } else {
      history.push("/errore-registrazione");
    }
  };

  return { catchErrorSignUp };
}

function useLoyaltyPrizeGeneration() {
  const { loyalty } = useSelector(formDataSelectors.getFormData);

  return async (accountId) => {
    const shouldCallGeneratePrizeEndpoint =
      !!loyalty?.sisalBonusPoints && !!loyalty?.sgadId;

    if (!shouldCallGeneratePrizeEndpoint) {
      return;
    }

    try {
      await postGeneratePrize(
        accountId,
        loyalty.sisalBonusPoints,
        loyalty.sgadId,
      );
    } catch (error) {
      console.error(error);
    }
  };
}

function isPromoError(responseData) {
  const errorInfosObj = responseData?.error?.errorInfos;
  return Object.keys(errorInfosObj ?? {}).some((err) => err === "promoCode");
}

async function getAndSetOnWindowSignUpRecaptchaToken() {
  const recaptchaToken = await window.grecaptcha.enterprise.execute(
    KEY_RE_CAPTCHA,
    { action: "REGISTRATION" },
  );
  window.grecaptcha.token = recaptchaToken;

  return recaptchaToken;
}

function trackSignupWithPixie(contoId) {
  try {
    window.pixie("event", "SignUp", { item_id: contoId });
  } catch {
    console.error("Pixie not found");
  }
}
