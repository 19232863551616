import { Translate } from "react-localize-redux";
import { useSelector } from "react-redux";
import { LOGIN_REDIRECT } from "../../../config";
import RightDesktopGift from "../../../images/svg/thankyouPage/right-desktop-gift.svg";
import RightMobileGift from "../../../images/svg/thankyouPage/right-mobile-gift.svg";
import { formDataSelectors } from "../../../store/formData/formData.selectors";
import {
  useAuthAction,
  useAuthActionSso,
  useCustomRedirect,
} from "../thankYouPage.hooks";
import { getAuthType } from "../../../utility/authType";

export default function ThankYouPageBonusBanner() {
  const {
    bonusList,
    bonusCode,
    promoCode,
    customizesBonus,
    isMobile,
    channel,
  } = useSelector(formDataSelectors.getFormData);
  const { authenticate } = useAuthAction();
  const { authenticateSso } = useAuthActionSso();
  const { customRedirect } = useCustomRedirect();

  const bonus = bonusList.find((b) => b.bonusCode === bonusCode);

  if (!bonus && !customizesBonus) return null;

  const handleRechargeDefault = async () => {
    window.postMessage({ event: "ricarica" }, "*");

    await authenticate();
    customRedirect(`${LOGIN_REDIRECT}/area-riservata/ricarica`);
  };

  const handleRechargeSsoForApps = async () => {
    const res = await authenticateSso();
    if (res.jwt) {
      // export the jwt token to the parent window, so that the apps can use it
      // to automatically login the user and redirect to the recharge page using
      // native functions
      window.postMessage({ operation: "recharge", value: { JWT: res.jwt } });
    }
  };

  const handleRecharge = async () => {
    if (getAuthType() === "sso" && channel !== 62) {
      handleRechargeSsoForApps();
    } else {
      handleRechargeDefault();
    }
  };

  return (
    <div className="sisal-thank-you-banner">
      <div className={"sisal-thank-you-banner-right-gift"}>
        <img
          alt="bonusRightGift"
          className="sisal-thank-you-banner-gift-img"
          src={isMobile ? RightMobileGift : RightDesktopGift}
        />
      </div>

      <div className="sisal-thank-you-banner-tag">
        <Translate id="text.typ.spid.welcomeBonus" />
      </div>

      <span className="sisal-thank-you-banner-title">
        {bonus ? bonus.title : promoCode}
      </span>

      <div className="sisal-thank-you-banner-description">
        <span className="sisal-thank-you-banner-text">
          {bonus
            ? bonus.description
            : "Segui le indicazioni per ottenere il bonus"}{" "}
        </span>

        <button
          className="sisal-thank-you-banner-link"
          onClick={handleRecharge}
        >
          <Translate id="text.typ.spid.reload" />
        </button>
      </div>
    </div>
  );
}
