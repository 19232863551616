export function setAuthType() {
  const urlParams = new URLSearchParams(window.location.search);
  const authType = urlParams.get("auth-type");

  if (authType === "sso") {
    window.sessionStorage.setItem("auth-type", "sso");
  }
}

export function getAuthType() {
  const authType = window.sessionStorage.getItem("auth-type");

  switch (authType) {
    case "sso":
      return "sso";
    default:
      return "classic";
  }
}
